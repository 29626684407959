import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.number.constructor.js";
import useBaseUtils from "~/functions/useBaseUtils";
import useBusinessMode from "~/functions/useBusinessMode";
import useCiqTableUtils from "~/functions/useCiqTableUtils";
import useCiqTableOptions from "~/functions/useCiqTableOptions";
export default defineComponent({
  props: {
    tbltitle: {
      type: String,
      "default": "Multiples"
    },
    dateHeaders: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    lineitems: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    metricObj: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    desiredUnits: {
      type: Number,
      "default": 1
    },
    decimals: {
      type: Number,
      "default": 2
    },
    // currencyToggle not used in valuation table, toUSD is used instead...
    // but it could be currencyToggle = 1, would be same thing
    currencyToggle: {
      type: Number,
      "default": 0
    },
    showTurns: {
      type: Boolean,
      "default": true
    },
    showAllEmptyRows: {
      type: Boolean,
      "default": true
    },
    // period is needed for the selectedRows object
    period: {
      type: String,
      "default": "a"
    } // valuation table is the table where specialPeriod is true... what was that all about?
  },
  setup: function setup(props) {
    // okay you're probably going to copy a fair amount from CiqEstimateTable.vue
    // so inclusion of variables isn't an endorsement that they are useful
    var _useBaseUtils = useBaseUtils(),
      freezeFirstColumn = _useBaseUtils.freezeFirstColumn,
      darkActive = _useBaseUtils.darkActive,
      onlyShowOrigEstMetrics = _useBaseUtils.onlyShowOrigEstMetrics,
      I18nFn = _useBaseUtils.I18nFn;
    var _useBusinessMode = useBusinessMode(),
      hidePrice = _useBusinessMode.hidePrice; // const { utcEpochToShortDate: formatDate } = useDateTimeFormatters()
    var _useCiqTableUtils = useCiqTableUtils(props),
      selectedRows = _useCiqTableUtils.selectedMultipleRows,
      addRowToSelectedObject = _useCiqTableUtils.addRowToSelectedObject,
      rowObjToSelectedKey = _useCiqTableUtils.rowObjToSelectedKey,
      removeRowFromSelectedObject = _useCiqTableUtils.removeRowFromSelectedObject,
      glossary = _useCiqTableUtils.glossary;
    var iconColor = darkActive.value ? "white" : "#838383";
    var _useCiqTableOptions = useCiqTableOptions({
        storeModule: "ciq",
        tableType: "estimates"
      }),
      reverseDates = _useCiqTableOptions.reverseDates,
      findMetricKey = _useCiqTableOptions.findMetricKey;
    var displayRow = function displayRow(tblDataObj, lineitem, hidePrice) {
      var denomKey = findMetricKey(lineitem.denominator);
      var numerKey = findMetricKey(lineitem.numerator);
      var denomObj = tblDataObj[denomKey];
      var numerObj = tblDataObj[numerKey];
      var priceDataItemIds = ["priceclose", "mc", "tev", "100161", "100162", "100163", "100164", "100165"];
      if (lineitem.dataitemid.length === 1) {
        var dataObj = tblDataObj[findMetricKey(lineitem.dataitemid[0])];
        if (dataObj) {
          if (hidePrice) {
            var priceMetric = priceDataItemIds.includes(lineitem.dataitemid[0]);
            return !priceMetric; // true means show the row
          } else {
            return lineitem.tikrdisplay === "1";
          }
        }
      } else if (denomObj && numerObj) {
        return lineitem.tikrdisplay === "1";
      }
      return false;
    };
    var generateRowClasses = function generateRowClasses(row) {
      var classArray = row.format.split(" ");
      classArray.push("datarow");
      var rowId = rowObjToSelectedKey(row);
      if (selectedRows.value[rowId]) {
        classArray.push("clicked");
      }
      return classArray;
    };
    var estRowClick = function estRowClick(e, row) {
      if (row.formula === "h3") {
        return;
      }
      var rowId = rowObjToSelectedKey(row);
      if (selectedRows.value[rowId]) {
        removeRowFromSelectedObject(rowId, "ntm", "multiplesChart"); // FIXME: what is props.period how does it work with these charts...
      } else {
        row.rowId = rowId;
        addRowToSelectedObject(row, rowId, "ntm", "multiplesChart");
      }
    };
    return {
      freezeFirstColumn: freezeFirstColumn,
      darkActive: darkActive,
      onlyShowOrigEstMetrics: onlyShowOrigEstMetrics,
      hidePrice: hidePrice,
      glossary: glossary,
      iconColor: iconColor,
      reverseDates: reverseDates,
      displayRow: displayRow,
      generateRowClasses: generateRowClasses,
      estRowClick: estRowClick,
      findMetricKey: findMetricKey,
      I18nFn: I18nFn
    };
  },
  computed: {
    toUSD: {
      get: function get() {
        return this.$store.state.ciq.compareMultiplesToUSD;
      },
      set: function set(value) {
        this.$store.commit("ciq/setToggle", {
          type: "compareMultiplesToUSD",
          value: value
        });
      }
    }
  }
});