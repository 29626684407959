import useBaseUtils from "~/functions/useBaseUtils";
var lambdaStage = process.env.LAMBDA_STAGE;
export default defineComponent({
  setup: function setup() {
    var _useBaseUtils = useBaseUtils(),
      sectionWrapperStyle = _useBaseUtils.sectionWrapperStyle;
    return {
      sectionWrapperStyle: sectionWrapperStyle
    };
  },
  data: function data() {
    return {
      template: []
    };
  },
  computed: {
    data: function data() {
      var data = this.$store.state.ciq.ntm ? this.$store.state.ciq.ntm[12] : {};
      return data;
    },
    error: function error() {
      if (this.$store.state.ciq.valuationError) {
        return this.$store.state.ciq.valuationError;
      } // check to see if all of the dataitemids are on the object
      /**
             * 100186 Revenues
             * 100221 EBIT
             * 100235 EBT Normalized
             * 100256 Net Income Normalized
             * 100179 EPS Normalized
             * 100214 Dividends / Share
             */ // TODO: not being used
      // const alldataitems = [100186, 100221, 100235, 100256, 100179, 100214]
      // const requiredDataItems = [100186, 100221, 100235, 100256, 100179]
      // const resData = this.data ? this.data.resData : {}
      // const presentData = requiredDataItems.map((d) => {
      //   const dData = resData[d] ? resData[d].actual : false
      //   return dData
      // })
      // check for these things throw error showing whats not avaialble?
      return this.$store.state.ciq.valuationError;
    },
    loading: function loading() {
      return this.$store.state.ciq.fetchingValuation;
    },
    stage: function stage() {
      return lambdaStage;
    }
  }
});