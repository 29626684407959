import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/esnext.set.add-all.js";
import "core-js/modules/esnext.set.delete-all.js";
import "core-js/modules/esnext.set.difference.js";
import "core-js/modules/esnext.set.every.js";
import "core-js/modules/esnext.set.filter.js";
import "core-js/modules/esnext.set.find.js";
import "core-js/modules/esnext.set.intersection.js";
import "core-js/modules/esnext.set.is-disjoint-from.js";
import "core-js/modules/esnext.set.is-subset-of.js";
import "core-js/modules/esnext.set.is-superset-of.js";
import "core-js/modules/esnext.set.join.js";
import "core-js/modules/esnext.set.map.js";
import "core-js/modules/esnext.set.reduce.js";
import "core-js/modules/esnext.set.some.js";
import "core-js/modules/esnext.set.symmetric-difference.js";
import "core-js/modules/esnext.set.union.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import chartButton from "~/components/charts/DailyChartButton.vue";
import useBaseUtils from "~/functions/useBaseUtils";
import useCiqTableOptions from "~/functions/useCiqTableOptions";
import useDateTimeFormatters from "~/functions/useDateTimeFormatters";
import StyledTabs from "~/components/StyledTabs";
import { valuationTemplate } from "~/utils/templates/valuation_template";
import { valuationTabs, findStatementIndex } from "~/utils/constants/tabs";
export default defineComponent({
  components: {
    chartButton: chartButton,
    StyledTabs: StyledTabs
  },
  props: {
    tableOptions: {
      type: Object,
      "default": function _default() {
        return {
          stateKey: "ntm",
          loadingKey: "fetchingValuation",
          errorKey: "valuationError",
          mainTabTitle: "Multiples"
        };
      }
    }
  },
  setup: function setup() {
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store,
      $route = _useNuxtApp.$route;
    var _useBaseUtils = useBaseUtils(),
      refCode = _useBaseUtils.refCode,
      freezeFirstColumn = _useBaseUtils.freezeFirstColumn,
      darkActive = _useBaseUtils.darkActive,
      I18nFn = _useBaseUtils.I18nFn,
      sectionWrapperStyle = _useBaseUtils.sectionWrapperStyle;
    var _useDateTimeFormatter = useDateTimeFormatters(),
      utcEpochToShortDate = _useDateTimeFormatter.utcEpochToShortDate,
      utcEpochToTwoDigitYear = _useDateTimeFormatter.utcEpochToTwoDigitYear; // FIXME:
    // currencyToggle being keyed to financials does not seem to make much sense
    // when you are currently not displaying the ability to toggle currencies
    var _useCiqTableOptions = useCiqTableOptions({
        storeModule: "ciq",
        tableType: "multiples"
      }),
      reverseDates = _useCiqTableOptions.reverseDates,
      decimals = _useCiqTableOptions.decimals,
      activePeriod = _useCiqTableOptions.activePeriod,
      dateRange = _useCiqTableOptions.dateRange,
      transformValuationTemplateIntoRows = _useCiqTableOptions.transformValuationTemplateIntoRows;
    var ticker = computed(function () {
      return $store.state.ciq.ticker || {};
    });
    var statementTabs = computed(function () {
      return valuationTabs.map(function (m) {
        return _objectSpread({
          to: "/stock/multiples?cid=".concat(ticker.value.companyid, "&tid=").concat(ticker.value.tradingitemid, "&tab=").concat(m.slug, "&ref=").concat(refCode.value),
          nuxt: true,
          exact: true
        }, m);
      });
    });
    var tblTemplateArr = valuationTemplate.map(function (t) {
      return t.keys;
    });
    var newTemplate = computed(function () {
      return transformValuationTemplateIntoRows($store, tblTemplateArr);
    });
    var initialStatementIndex = computed(function () {
      var tabQueryParam = $route.query.tab;
      var statementArr = statementTabs.value;
      return findStatementIndex(statementArr, tabQueryParam);
    }); // this is called activeStatement in Financial and Estimtae table wrapper
    var activeValuationTabIndex = ref(initialStatementIndex.value);
    var tid = computed(function () {
      return ticker.value.tradingitemid;
    });
    var dateMin = ref(0);
    var dateMax = ref(2);
    var periods = [{
      title: "Annual",
      value: "annual",
      period: "a"
    }, {
      title: "Quarterly",
      value: "quarter",
      period: "q"
    }];
    var setDateRange = function setDateRange(dates, activePeriod) {
      // perform your work here
      var max = dates.length - 1; // always want to show 4 periods
      var calcMin = activePeriod === 0 ? max - 4 : max - 5; // but calcMin can't be less than 0
      var actualMin = calcMin >= 0 ? calcMin : 0; // Now that we have the max and CalcMin, set them on
      // component data
      dateMin.value = actualMin;
      dateMax.value = max;
      dateRange.value = [actualMin, max];
    };
    var dates = computed(function () {
      var ciqKey = "addMultiples";
      var datObj = $store.state.ciq[ciqKey][tid.value] || {};
      var dates = datObj.dates || [];
      var filteredDates = dates.filter(function (period) {
        if (activePeriod.value === 1) {
          // remove all periods but quaterly period and latest period
          return period.calendarquarter;
        }
        if (activePeriod.value === 0) {
          // remove all periods but anual period and latest period
          // return period.calendarquarter === 4
          return period.calendarquarter === 4 || period.mostRecentDate // period.fiperiodenddate === lastDateString
          ;
        }
        return false;
      });
      return filteredDates;
    });
    var availableDates = computed(function () {
      setDateRange(dates.value.filter(function (f) {
        if (activeValuationTabIndex.value === 1) {
          return f.relativeconstant >= 492;
        }
        return true;
      }), activePeriod.value);
      return dates.value.map(function (d) {
        return Object.assign({}, d, {
          text: utcEpochToShortDate.value.format(d.epoch),
          annualLabel: "'".concat(utcEpochToTwoDigitYear.value.format(d.epoch)),
          quarterLabel: "".concat(d.calendarquarter, "Q").concat(utcEpochToTwoDigitYear.value.format(d.epoch))
        });
      }).filter(function (f) {
        if (activeValuationTabIndex.value === 1) {
          return f.relativeconstant >= 492;
        }
        return true;
      });
    });
    var dateHeaders = computed(function () {
      var periodId = new Set(); /**
                                * The dates array needs to be on the dates key
                                * headers needs to have
                                * estimateperiodid -> relativeconstant ... key to extract data
                                * d.periodenddate to make label -> quarterenddate or pricing date if relconst = 500
                                *
                                * basically the date object needs to contain the information to
                                * make the rendered label and the information that acts as
                                * the key to extract the data from the lineitem object
                                */
      var headers = availableDates.value.reduce(function (acc, d) {
        if (periodId.has(d.fiperiodenddate)) {
          return acc;
        }
        periodId.add(d.fiperiodenddate);
        acc.push(d);
        return acc;
      }, []).filter(function (f) {
        if (activeValuationTabIndex.value === 1) {
          return f.relativeconstant >= 492;
        }
        return true;
      }).filter(function (_f, i) {
        return i >= dateRange.value[0] && i <= dateRange.value[1];
      });
      if (reverseDates.value && activeValuationTabIndex.value === 0) {
        return headers.reverse();
      } else {
        return headers;
      }
    });
    return {
      refCode: refCode,
      freezeFirstColumn: freezeFirstColumn,
      darkActive: darkActive,
      reverseDates: reverseDates,
      decimals: decimals,
      formatDate: utcEpochToShortDate,
      activePeriod: activePeriod,
      dateRange: dateRange,
      periods: periods,
      activeValuationTabIndex: activeValuationTabIndex,
      ticker: ticker,
      tid: tid,
      dates: dates,
      availableDates: availableDates,
      dateHeaders: dateHeaders,
      I18nFn: I18nFn,
      statementTabs: statementTabs,
      findStatementIndex: findStatementIndex,
      sectionWrapperStyle: sectionWrapperStyle,
      newTemplate: newTemplate
    };
  },
  data: function data() {
    return {
      showTurns: true,
      cagrCol: false,
      interesting: false,
      stateKey: "ntm",
      loadingKey: "fetchingValuation",
      errorKey: "valuationError",
      mainTabTitle: "Multiples",
      specialPeriod: true,
      // idk what this does
      options: false,
      multiPeriod: 1,
      desiredUnits: 1,
      units: [{
        text: "Financials displayed in thousands",
        value: 0
      }, {
        text: "Financials displayed in millions",
        value: 1
      }, {
        text: "Financials displayed in billions",
        value: 2
      }]
    };
  },
  computed: {
    data: function data() {
      var data = this.$store.state.ciq.estimates;
      if (this.stateKey !== "estimates") {
        data = this.$store.state.ciq[this.stateKey];
      }
      return data;
    },
    period: function period() {
      var period = "ntm";
      if (this.activePeriod === 0) {
        if (this.specialPeriod) {
          // period = 12
          period = "ntm";
        }
      } else if (this.specialPeriod) {
        // period = 12
        period = "ntm";
      }
      return period;
    },
    estimates: function estimates() {
      var period = this.period;
      var finData = this.data;
      if (finData[period]) {
        return finData[period].estimates || [];
      } else {
        return [];
      }
    },
    datObj: function datObj() {
      var tid = this.tid;
      var ciqKey = "addMultiples";
      if (tid) {
        try {
          return this.$store.state.ciq[ciqKey][tid] || {};
        } catch (error) {
          console.error("No Multiples datObj: ", error);
          return {};
        }
      } else {
        return {};
      }
    },
    tblDataObj: function tblDataObj() {
      return this.datObj.tblDataObj || {};
    },
    lastDate: function lastDate() {
      return this.availableDates[this.availableDates.length - 1] || {
        text: null
      };
    },
    loading: function loading() {
      return this.$store.state.ciq[this.loadingKey];
    },
    error: function error() {
      return this.$store.state.ciq[this.errorKey];
    },
    selectedRows: function selectedRows() {
      var period = this.period;
      var finData = this.$store.state.ciq.multiplesChart;
      if (finData[period]) {
        return finData[period] || {};
      } else {
        return {};
      }
    },
    rowsForChart: function rowsForChart() {
      // chart objects created by dailyMultiplesRes... manipulate yAxis there
      var finData = this.$store.state.ciq.dailyMultiples || {};
      return Object.keys(this.selectedRows).reduce(function (acc, rowId) {
        var data = finData[rowId];
        if (data) {
          acc.push(data);
        }
        return acc;
      }, []);
    }
  },
  watch: {
    tableOptions: {
      immediate: true,
      handler: function handler(options) {
        this.stateKey = options.stateKey || "ntm";
        this.loadingKey = options.loadingKey || "fetchingValuation";
        this.errorKey = options.errorKey || "valuationError";
        this.mainTabTitle = options.mainTabTitle || "Multiples";
        this.specialPeriod = options.specialPeriod || true;
      }
    },
    "$route.query.tab": {
      handler: function handler(slug) {
        this.activeValuationTabIndex = this.findStatementIndex(this.statementTabs, slug);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    try {
      this.$nextTick(function () {
        try {
          _this.$vuetify.goTo("#stocktabbar");
        } catch (error) {
          console.error("Error .goto from multiplesTable: ", error);
        }
      });
    } catch (error) {
      console.error("Error .goto from multiplesTable: ", error);
    }
  },
  methods: {
    copyTable: function copyTable() {
      var _this2 = this;
      try {
        this.showTurns = false;
        this.$nextTick(function () {
          var table = document.querySelector("table.fintab");
          var range = document.createRange(); // console.log("table node ", table.tHead)
          // FIXME: change table.thead.style?
          var sel = window.getSelection();
          sel.removeAllRanges();
          range.selectNode(table);
          sel.addRange(range);
          document.execCommand("copy");
          _this2.showTurns = true;
        });
      } catch (error) {
        console.error("Error copying table: ", error); // perform more error handling here?
      }
    },
    removeRowFromSelectedObject: function removeRowFromSelectedObject(rowId) {
      var payload = {
        rowId: rowId,
        period: "ntm",
        chartType: "multiplesChart"
      };
      this.$store.commit("ciq/removeFromChart", payload);
    },
    determineDataObject: function determineDataObject(lineitem, dateObj) {
      // Function goal - take the lineitem and dateObj and return
      // the correct object containing the dataitemvalue because the actual
      // data object is stored under the key 'mean' 'median' 'actual'... eventually high/low as well
      // First, determine the type of date (actual, mean, median)
      // FIXME: This is where you would perform logic to switch between mean and median
      var dateType = dateObj.isEstimate ? "mean" : "actual";
      if (this.specialPeriod) {
        dateType = "actual";
      }
      var periodId = dateObj.value; // check lineitem[dataType], return the dataitemvalue object or undefined
      return lineitem[dateType] ? lineitem[dateType][periodId] : undefined;
    },
    makeDateLabels: function makeDateLabels(date, index) {
      if (this.period === "a") {
        // its annual
        // if (arr.length - 1 === index) {
        //   return date.label
        // }
        if (index % 2) {
          return "";
        }
        return date.annualLabel;
      } else {
        // its quarters
        if (date.calendarquarter === 4) {
          return date.annualLabel;
        }
        return "";
      }
    },
    makeThumbLabels: function makeThumbLabels(date) {
      if (this.activePeriod === 0) {
        // its annual
        return date ? date.text : "";
      } else {
        // its quarterly
        return date ? date.quarterLabel : "";
      }
    }
  }
});