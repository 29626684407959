var LAMBDA_STAGE = process.env.LAMBDA_STAGE;
export default defineComponent({
  computed: {
    stage: function stage() {
      return LAMBDA_STAGE === "dev";
    }
  },
  mounted: function mounted() {
    this.$emit("closechart");
  }
});