var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{attrs:{"dense":""}},[(_vm.error)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_vm._v("\n    "+_vm._s(`${_vm.$Amplify.I18n.get("Sorry, we have no valuation data for")} ${
        _vm.ticker.exchangename
      } ${_vm.$Amplify.I18n.get("ticker")} ${_vm.ticker.tickersymbol}`)+"\n  ")]):(!_vm.loading)?[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","order":"1"}},[_c('v-card',{style:(_vm.sectionWrapperStyle),attrs:{"elevation":"0"}},[_c('CiqTableOptions',{attrs:{"fluid":true,"table-type":"multiples","periods":_vm.periods,"available-dates":_vm.availableDates,"tick-labels":_vm.availableDates.map(_vm.makeDateLabels),"date-headers":_vm.dateHeaders,"ticker":_vm.ticker,"alert-not-default-ticker":true,"alert-tbl-description":false,"display-empty-rows-toggle":false,"display-units-toggle":false,"display-currency-toggle":false},on:{"copytable":_vm.copyTable}})],1)],1),_vm._v(" "),_c('v-col',{staticClass:"py-0 mt-4",attrs:{"cols":_vm.interesting ? 6 : 12,"order":"2"}},[_c('chartButton',{attrs:{"selectedrows":_vm.selectedRows,"rowsforchart":_vm.rowsForChart,"columndecimals":_vm.decimals,"charttitle":_vm.ticker.tickersymbol
            ? `${_vm.ticker.companyname} (${_vm.ticker.tickersymbol})`
            : '',"chartsymbol":_vm.ticker.tickersymbol,"loading":_vm.loading},on:{"removerow":_vm.removeRowFromSelectedObject}})],1),_vm._v(" "),_c('v-col',{staticClass:"pt-0",attrs:{"cols":_vm.interesting ? 6 : 12,"order":"3"}},[_c('v-card',{staticClass:"mt-4 pt-2",style:(_vm.sectionWrapperStyle),attrs:{"elevation":"0"}},[_c('StyledTabs',{attrs:{"tablist":_vm.statementTabs,"show-arrows":true,"old-layout":true},on:{"change":(e, idx) => (_vm.activeValuationTabIndex = idx)}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"12","justify":"center","align":"center"}},[_vm._l((_vm.newTemplate),function(tblRows,i){return [(_vm.activeValuationTabIndex === i)?_c('v-responsive',{key:i,staticClass:"tblcontainer d-inline-block",class:{ lighttableshadows: !_vm.$vuetify.theme.dark },attrs:{"max-height":"90vh"}},[_c('CiqValuationTable',{key:_vm.statementTabs[i].label,attrs:{"tbltitle":_vm.statementTabs[i].label,"date-headers":_vm.dateHeaders,"lineitems":tblRows,"metric-obj":_vm.tblDataObj,"desired-units":_vm.desiredUnits,"decimals":_vm.decimals,"show-turns":_vm.showTurns,"period":_vm.period}})],1):_vm._e()]}),_vm._v(" "),(_vm.activeValuationTabIndex === 2)?[_c('CiqCompetitorsTable')]:_vm._e()],2),_vm._v(" "),(_vm.activeValuationTabIndex === 0)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-caption"},[_vm._v("\n                  "+_vm._s(_vm.$Amplify.I18n.get(
                      "NTM = Next Twelve Months     LTM = Last Twelve Months"
                    ))+"\n                ")])]):_vm._e()],1)],1)],1)],1)],1)]:_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-progress-circular',{staticClass:"quote",attrs:{"size":70,"width":7,"color":"primaryAction","indeterminate":""}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }