var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('table',{staticClass:"fintab"},[_c('colgroup',[_vm._l((_vm.dateHeaders),function(date){return _c('col',{key:`${date.value}-col`,staticClass:"datacol"})}),_vm._v(" "),_c('col',{staticClass:"datacol"})],2),_vm._v(" "),_c('thead',[_c('tr',{staticClass:"tableheader"},[_c('th',{class:{
          fixedfirstheader: _vm.freezeFirstColumn,
          description: !_vm.freezeFirstColumn,
          'py-1': true,
          'pl-1': true,
          'text-left': true,
        }},[_vm._v("\n        "+_vm._s(`${_vm.$Amplify.I18n.get(_vm.tbltitle)}${
            _vm.$device.isDesktopOrTablet ? " | TIKR.com" : ""
          }`)+"\n      ")]),_vm._v(" "),_vm._l((_vm.dateHeaders),function(date,idx){return _c('th',{key:`${date.value}-head`,class:{
          'text-right': true,
          'py-1': true,
          'pr-1': idx === _vm.dateHeaders.length - 1,
        }},[_vm._v("\n        "+_vm._s(`${date.text}`)+"\n      ")])})],2)]),_vm._v(" "),_c('tbody',[_vm._l((_vm.lineitems),function(t,idx){return [(
          t.formula === 'h3' &&
          _vm.lineitems.length - 1 > idx &&
          _vm.lineitems[idx + 1].formula !== 'h3'
        )?_c('tr',{key:idx + 'title',staticClass:"pt-3 bold italic"},[_c('td',{class:{
            fixedfirstrow: _vm.freezeFirstColumn,
            description: !_vm.freezeFirstColumn,
            fixedfirstrowcolorlight: !_vm.darkActive,
            fixedfirstrowcolordark: _vm.darkActive,
          }},[_vm._v("\n          "+_vm._s(_vm.I18nFn(t.name.trim()))+"\n        ")])]):(_vm.displayRow(_vm.metricObj, t, _vm.hidePrice))?_c('tr',{key:idx + 'multi',class:_vm.generateRowClasses(t),on:{"click":function($event){return _vm.estRowClick($event, t)}}},[_c('td',{class:{
            fixedfirstrow: _vm.freezeFirstColumn,
            description: !_vm.freezeFirstColumn,
            fixedfirstrowcolorlight: !_vm.darkActive,
            fixedfirstrowcolordark: _vm.darkActive,
          }},[_vm._v("\n          "+_vm._s(_vm.I18nFn(t.name.trim()))+"\n          "),(_vm.glossary[t.name.trim()] && _vm.showTurns)?[_c('v-tooltip',{attrs:{"bottom":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"x-small":"","color":_vm.iconColor}},on),[_vm._v("\n                  mdi-information-outline\n                ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("\n                "+_vm._s(_vm.glossary[t.name.trim()])+"\n              ")])])]:_vm._e()],2),_vm._v(" "),_vm._l((_vm.dateHeaders),function(d,dateIdx){return _c('ValuationTblCell',{key:`${d.text}${t.denominator}`,staticClass:"text-right",attrs:{"curr-date-obj":d,"prev-date-obj":_vm.dateHeaders[dateIdx - 1],"denom-data-obj":_vm.metricObj[_vm.findMetricKey(t.denominator)],"numer-data-obj":_vm.metricObj[_vm.findMetricKey(t.numerator)],"lineitem":t,"tousd":_vm.toUSD,"showturns":_vm.showTurns,"decimals":_vm.decimals,"datelength":_vm.dateHeaders.length,"dateidx":dateIdx,"reversedates":_vm.reverseDates}})})],2):_vm._e()]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }